import React, {Fragment, useEffect, useCallback} from 'react';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
//import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LinkLogo from '../images/tts_logo_long.png';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReCAPTCHA from "react-google-recaptcha";
import IconButton from "@material-ui/core/IconButton";
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputMask from 'react-input-mask'
import { LinearProgress } from '@material-ui/core';
import IconCheck from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  loading: {
    minWidth: '300px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  paper: {
    margin: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  termsCheckBox : {
    fontSize: '12px',
  },
  terms : {
    fontSize: '20px',
    paddingTop: '8px'
  },
  termsLink: {
    cursor: 'pointer'
  },
  registerNowButton : {
    width: '200px',
    color: '#FFFFFF',
    backgroundColor: 'gray',
    display: 'block',
    borderRadius: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#2286DA'
    }
  },
  registerNowButtonForm : {
    marginTop: '5%',
    width: '200px',
    color: '#FFFFFF',
    backgroundColor: 'gray',
    display: 'block',
    borderRadius: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#2286DA'
    }
  },
  formContainerSuccess : {
    width: '95%',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
  formContainer : {
    width: '95%',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  dateOfBirthInputsLabel: {
    paddingBottom: '10px',
    fontSize: '14px'
  },
  dateOfBirth : {
    paddingTop: '20px',
    fontSize: '20px',
    width: '25%'
  },
  dateOfBirthMobile : {
    paddingTop: '20px',
    fontSize: '20px',
    width: '100%'
  },
  dateOfBirthInputs: {
    width: "100%"
  },
  dateOfBirthInput : {
    marginRight: '25px'
  },
  dateOfBirthInputMobile: {
    width: '100%',
  },
  dateOfBirthSelectInput : {
    height: '100%',
    minWidth: '200px',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '14px'
  },
  dateOfBirthDayInput : {
    minWidth: '10%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  dateOfBirthDayInputField: {
    paddingTop: '10px',
    paddingRight: '10px'
  },
  dateOfBirthYearInputField: {
    paddingTop: '10px',
    paddingLeft: '10px'
  },
  dateOfBirthYearInput : {
    minWidth: '10%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  dateOfBirthSelectLabel: {
    color: 'gray',
    fontSize: '11px',
    paddingBottom: '5px'
  },
  cdcScreeningSurvey : {
    paddingTop: '20px',
    paddingBottom: '20px',
    fontSize: '20px',
    border: '1px solid grey'
  },
  cdcScreeningSurveyLeftColHeader: {
    width: '60%',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  cdcScreeningSurveyRightColHeader: {
    width: '40%',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  cdcScreeningSurveyLeftCol: {
    width: '58%',
    textAlign: 'left',
    marginLeft: '1%',
    marginRight: '1%'
  },
  cdcScreeningSurveyRightCol: {
    width: '38%',
    textAlign: 'center',
    marginLeft: '1%',
    marginRight: '1%'
  },
  no : {
    width: '100%',
    color: 'green',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: 'green'
    },
    height: '100%'
  },
  noSelected : {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: 'green',
    borderRadius: 0,
    height: '100%'
  },
  yes : {
    width: '100%',
    color: '#CC0006',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#CC0006'
    },
    height: '100%'
  },
  yesSelected : {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: '#CC0006',
    borderRadius: 0,
    height: '100%'
  },
  yesNoButtonsContainer: {
    height: '100%'
  },
  yesNoButtonsElement: {
    alignItems: 'stretch',
    height: '100%'
  },
  approved: {
    color: '#FFFFFF',
    backgroundColor: '#2A78E4',
    width: '100%',
    textAlign: 'center',
  },
  notApproved: {
    color: '#FFFFFF',
    backgroundColor: '#CC0006',
    width: '100%',
    textAlign: 'center',
  },
  notClearToEnter: {
    color: '#CC0006',
    fontSize: 30
  },
  registrationCompleteIcon: {
    color: 'green',
    width: '50%',
    minHeight: '15vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: '35px'
  },
  overallFormErrorMessage : {
    color: '#CC0006',
    fontSize: 14
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://linktts.com/" target="_blank">
        LINK: Total Testing Solutions
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

class FormContainer extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        invalidCode: false,
        loading: true,
        showTerms : false,
        showForm : false,
        errors : {},
        approvedToEnter: false,
        notApprovedToEnter: false,
        registrationComplete: false,
        user : {
          firstName : '',
          lastName : '',
          email : '',
          phone: '',
          dobDay : '',
          dobMonth : '',
          dobYear : '',
        },
        monthOptions: [
  				{ value: '01', label: 'January' },
  				{ value: '02', label: 'February' },
  				{ value: '03', label: 'March' },
  				{ value: '04', label: 'April' },
  				{ value: '05', label: 'May' },
          { value: '06', label: 'June' },
          { value: '07', label: 'July' },
          { value: '08', label: 'August' },
          { value: '09', label: 'September' },
          { value: '10', label: 'October' },
          { value: '11', label: 'November' },
          { value: '12', label: 'December' },
			  ],
        hasInitialized : false,
        selectedSurveyIndex: -1,
        surveyAnswers: [],
        surveys: []
      }
  }
  componentDidMount() {
    this.setState({ selectedSurveyIndex: 0 });
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        //body: JSON.stringify({ title: 'React GET Request Example' })
    };
    const hashedLocation = window.location.hash
    var code;
    if (hashedLocation && hashedLocation.lastIndexOf("/") > 0)
      code = hashedLocation.substring(hashedLocation.lastIndexOf("/") + 1, hashedLocation.length)
    if (code != null && code.length > 0) {
      fetch('/api/v1/registration/survey/' + code, requestOptions)
        .then(response => response.json())
        .then(surveyData => {
          if (!surveyData || (!Array.isArray(surveyData) && surveyData.success !== null && surveyData.success === false) || (surveyData.status && surveyData.status >= 400)) {
            this.setState({ invalidCode: true, loading: false })
          } else {
            this.setState({ surveys: surveyData, loading: false })
          }
        }).catch(({ status, statusText, headers, body }) => {
          console.log("status ", body);
          console.log("statusText ", statusText);
          console.log("headers ", headers);
          console.log("body ", body);
        })
    } else {
      this.setState({ invalidCode: true, loading: false })
    }
    //setTimeout(function() {
      //document.getElementById('OpenSurvey').click();
    //}, 5);
  }
  handleRegistrationUserChange(field, e){
    let user = this.state.user;
    user[field] = e.target.value;
    this.setState({ user });
  }

  render() {
    const {
      showTerms,
      showForm,
      user,
      errors,
      surveys,
      loading,
      invalidCode,
      approvedToEnter,
      notApprovedToEnter,
      registrationComplete
    } = this.state;
      const self = this;
      const recaptchaRef = React.createRef();
      self.closeTerms = () => {
        self.setState({
          showTerms : false
        });
      }
      //const classes = useStyles();

      self.showTerms = () => {
        self.setState({
          showTerms : true
        });
      }
      // executed once the captcha has been verified
      // can be used to post forms, redirect, etc.
      self.verifyCallback = (response) => {
        //console.log(response);
        //document.getElementById("someForm").submit();
      };
      // specifying your onload callback function
      self.onloadCallback = () => {
        //console.log('onloadCallback Done!!!!');
      };
      function onChange(value) {
        //console.log("Captcha value:", value);
        self.setState({
          recaptcha : value
        });
      }
      self.onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.props.onSubmit(recaptchaValue);
      }
      self.registerNow = () => {
        const recaptchaValue = self.state.recaptcha
        if (recaptchaValue != null && recaptchaValue.length > 0) {
          self.setState({
            showForm : true,
            hasInitialized: false
          });
          setTimeout(function() {
            document.getElementById('OpenSurvey').click()
          }, 200)
        }
      }
      const handleRegistrationChange = (event) => {
        const { user } = self.state;
        user[event.target.name] = event.target.value;
        self.setState({ user });
      }
      const handleMonthChange = (value) => {
        const { user } = self.state;
        user['dobMonth'] = value
        self.setState({ user });
      }
      const validateEmail = (email) => {
         const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,9}[.][a-z]{2,5}/g;
         const result = pattern.test(email);
         return result
       }

      const handleValidation = () => {
        let fields = self.state.user;
        let errors = {};
        let formIsValid = true;
        //console.log("Inside handleValidation")
        //Name
        if(!fields["firstName"]){
          formIsValid = false;
          errors["firstName"] = "First name cannot be blank";
        }
        if(!fields["lastName"]){
          formIsValid = false;
          errors["lastName"] = "Last name cannot be blank";
        }
        if(!fields["email"]){
          formIsValid = false;
          errors["email"] = "Email address cannot be blank";
        }
        if (fields["email"] && !validateEmail(fields["email"])) {
          errors["email"] = "Please Enter valid email address";
        }
        if(!fields["dobDay"]){
          formIsValid = false;
          errors["dob"] = "Date of Birth cannot be blank";
        }
        if(!fields["dobMonth"]){
          formIsValid = false;
          errors["dob"] = "Date of Birth cannot be blank";
        }
        if(!fields["dobYear"]){
          formIsValid = false;
          errors["dob"] = "Date of Birth cannot be blank";
        }
        self.setState({errors: errors});
        return formIsValid;
      }
      const registrationSubmit = (e) => {
        e.preventDefault();
        if(handleValidation()){
           self.setState({loading: true});
           //console.log("self.state.surveyAnswers ", self.state.surveyAnswers);
           // language determined by survey choice
           // 1 == en, 2 == es
           const hashedLocation = window.location.hash
           var code;
           if (hashedLocation && hashedLocation.lastIndexOf("/") > 0)
             code = hashedLocation.substring(hashedLocation.lastIndexOf("/") + 1, hashedLocation.length)
           const { user } = self.state;
           const selectSurvey = self.state.surveys[self.state.selectedSurveyIndex];
           /*
           if (selectSurvey.questions && selectSurvey.questions.length > 0 && self.state.surveyAnswers) {
             for (var i = 0; i < selectSurvey.questions.length; i++) {
                selectSurvey.questions[i].answer = self.state.surveyAnswers[i] === 'YES'
             }
           }
           */
           let surveySummary = {
             name : selectSurvey.name,
             surveyId : selectSurvey.id,
             passed : true,
           }
           const requestOptions = {
               method: 'POST',
               headers: { 'Content-Type': 'application/json' },
               body: JSON.stringify(
                 {
                   email: user['email'],
                   firstName: user['firstName'],
                   lastName: user['lastName'],
                   phone: user['phone'],
                   dob: user['dobYear'] + "-" + user['dobMonth'].value + "-" + user['dobDay'],
                   language: self.state.selectedSurveyIndex === 0 ? "en" : "es",
                   testResults : JSON.stringify(surveySummary)
                 }
               )
           };
           fetch('/api/v1/registration/' + code, requestOptions)
             .then(response => response.json())
             .then(registrationResponse => {
               //console.log("registration response ", registrationResponse);
               if (!registrationResponse || (registrationResponse.success !== null && registrationResponse.success === false) || (registrationResponse.status && registrationResponse.status >= 400)) {
                 let errors = {};
                 if (registrationResponse.success === false && registrationResponse.details && registrationResponse.details.length > 0) {
                   for (var i = 0; i < registrationResponse.details.length; i++) {
                      errors[registrationResponse.details[i].field] = registrationResponse.details[i].message
                   }
                 }
                 errors["form"] = "Please fix the following errors:"
                 self.setState({errors: errors, loading: false});
               } else {
                 self.setState({registrationComplete: true, showForm: false, loading: false});
               }
             }).catch(({ status, statusText, headers, body }) => {
               console.log("status ", body);
               console.log("statusText ", statusText);
               console.log("headers ", headers);
               console.log("body ", body);
             })
        }
      }
      function SurveyDialog() {
        const classes = useStyles();
        const [open, setOpen] = React.useState(false);
        const [approvedToEnter, setApprovedToEnter] = React.useState(true);
        const [notApprovedToEnter, setNotApprovedToEnter] = React.useState(false);
        const [selectedSurvey, setSelectedSurvey] = React.useState({ questions: []});
        const [selectedSurveyIndex, setSelectedSurveyIndex] = React.useState(0);
        const [answers, setAnswers] = React.useState([]);

        const handleClickOpen = () => {
          console.log("Inside handleClickOpen");
          setOpen(true);
        };
        const handleClose = () => {
          setOpen(false);
          self.setState({
            approvedToEnter : approvedToEnter,
            notApprovedToEnter: notApprovedToEnter
          });
        };

        const handleSurveyChange = (index) => {
          setSelectedSurveyIndex(index)
          setOpen(true);
          setSelectedSurvey(surveys[index]);
        };

        const answerQuestion = (index, value) => {
          //var anotherIndex = 0;
          //selectedSurvey.questions.forEach(function(question) {
          //console.log("Setting question answer for ", index);
          setQuestionAnswer(index, value)
          setTimeout(function() {
            setSelectedSurvey(selectedSurveyIndex);
          },300)
        }

        const setQuestionAnswer = useCallback((index, value) => {
          let answer = answers[index];
          answer = value
          answers[index] = answer
          setAnswers(answers)
          self.state.surveyAnswers = answers
          var answerCheckIndex = 0
          setNotApprovedToEnter(false)
          setApprovedToEnter(true)
          if (selectedSurvey && selectedSurvey.questions) {
            selectedSurvey.questions.forEach(function(question) {
              if(selectedSurvey.questions[answerCheckIndex].neededForPass === true && answers[answerCheckIndex] === 'YES') {
                setApprovedToEnter(false)
                setNotApprovedToEnter(true)
              }
              answerCheckIndex++
            });
          }
        }, [answers, selectedSurvey])

        useEffect(() => {
          //console.log("Inside useEffect")
          var index = 0;
          setSelectedSurvey(surveys[selectedSurveyIndex]);
          if (!self.state.hasInitialized && selectedSurvey && selectedSurvey.questions) {
            selectedSurvey.questions.forEach(function(question) {
              //console.log("Setting question answer to no for ", question);
              setQuestionAnswer(index++, 'NO')
            });
            if (selectedSurvey.questions && selectedSurvey.questions.length > 0)
              self.state.hasInitialized = true
          }
        }, [selectedSurvey, setQuestionAnswer, answers, selectedSurveyIndex]);

        return (
          <div>
            <Button style={{ display: 'none' }} id="OpenSurvey" variant="outlined" color="primary" onClick={handleClickOpen}>
              Open alert dialog
            </Button>
            <Dialog
              fullWidth={true} maxWidth={"md"}
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{false && selectedSurvey && selectedSurvey.name}
                {selectedSurveyIndex === 1 && surveys.length > 1 && <Button style={{float: 'right'}} onClick={() => handleSurveyChange(0)} color="primary">
                  English
                </Button> }
                {selectedSurveyIndex === 0 && surveys.length > 1 &&<Button style={{float: 'right'}} onClick={() => handleSurveyChange(1)} color="primary">
                  Español
                </Button>}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {selectedSurvey && selectedSurvey.header}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {selectedSurvey && selectedSurvey.prefix}
                </DialogContentText>
                <Fragment>
                  {selectedSurvey && selectedSurvey.questions && selectedSurvey.questions.map(function(name, index) {
                   return <Box key={index} display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.cdcScreeningSurveyLeftCol}>
                      {selectedSurvey && selectedSurvey.questions[index].prompt}
                    </Box>
                    <Box display="flex-row" alignContent="center" justifyContent="center" className={classes.cdcScreeningSurveyRightCol}>
                      <Box display="flex" alignContent="stretch" justifyContent="center" className={classes.yesNoButtonsContainer}>
                        <Box flexGrow={1} className={classes.yesNoButtonsElement}>
                          <IconButton
                            onClick={() => answerQuestion(index, 'YES')}
                            className={answers[index] === 'YES' ? classes.yesSelected : classes.yes}
                          >
                            {selectedSurvey && selectedSurvey.yes}
                          </IconButton>
                        </Box>
                        <Box flexGrow={1} className={classes.yesNoButtonsElement}>
                          <IconButton
                            onClick={() => answerQuestion(index, 'NO')}
                            className={answers[index] === 'NO' ? classes.noSelected : classes.no}
                          >
                            {selectedSurvey && selectedSurvey.no}
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  })}
                  {false && approvedToEnter && <Box display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.approved}>
                      APPROVED
                    </Box>
                  </Box>}
                  {false && notApprovedToEnter && <Box display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.notApproved}>
                      NOT APPROVED
                    </Box>
                  </Box> }
               </Fragment>
              </DialogContent>
              <DialogActions style={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}}>
                  {approvedToEnter && selectedSurvey && <Box display="flex" alignContent="stretch" justifyContent="center">
                    {false && selectedSurvey.footer}
                  </Box>}
                  <Box display="flex" alignContent="stretch" justifyContent="center" >
                    {false && approvedToEnter && <Button onClick={handleClose} color="primary">
                      {selectedSurvey && selectedSurvey.no}
                    </Button> }
                    {false && approvedToEnter && <Button onClick={handleClose} color="primary" autoFocus>
                      {selectedSurvey && selectedSurvey.yes}
                    </Button>}
                    <Button onClick={handleClose} color="primary" autoFocus>
                      Submit
                    </Button>
                  </Box>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
      return (
        <>
        {loading && <div className={self.props.classes.loading}>
          <LinearProgress />
        </div> }
        { !loading && invalidCode && <div className={self.props.classes.loading}>
        <Typography component="h1" variant="h5">
          Code Error Placeholder: Please click on the access email sent
        </Typography>
        </div> }
        <SurveyDialog />
        <Dialog id="LinkTerms" fullWidth={true} maxWidth={"md"} disableBackdropClick disableEscapeKeyDown open={showTerms} >
            <DialogTitle><center>LINK Terms and Conditions of Use</center></DialogTitle>
            <DialogContent>
              By signing below, you (a) acknowledge that you are providing "tenant" and its affiliates and contractors (collectively, "tenant"), or granting "tenant" access to, information that may be considered Protected Health Information (PHI) under the Health Insurance Portability and Accountability Act (HIPAA) or medical information under the Confidentiality of Medical Information Act (CMIA); and (b) voluntarily consent to "tenant's" electronic use, transmission, and disclosure of your PHI for the purpose of obtaining or verifying your COVID-19 test result and/or COVID-19 vaccination status.  Your consent will expire fourteen (14) days following the date below.  In the event that a COVID-19 test is required by "tenant," you hereby also consent to the risks associated with performing that test, including (but not limited to), false positive results, false negative results, damage to nasal cavity, or epistaxis (nose bleeds).
            </DialogContent>
          <DialogActions>
            <Button
              label="Close"
              className={self.props.classes.registerNowButton}
              onClick={() => self.closeTerms() }
            >
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
          { !showForm && !loading && !invalidCode && !registrationComplete && <>
            <form className={self.props.classes.form} noValidate onSubmit={self.onSubmit} >
              <Box display="flex" justifyContent="center" >
                <Typography component="h1" variant="h5">
                  Welcome to LINK.  Let&apos;s get you pre-registered!
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" style={{ width: '98%', marginLeft: '1%', marginRight: '1%', }}>
                <Box display="flex-row" justifyContent="flex-start" className={self.props.classes.termsCheckBox}>
                  <FormControlLabel
                    control={<Checkbox value="agree" color="primary" />}
                    label=""
                  />
                </Box>
                <Box display="flex-row" justifyContent="flex-start" className={self.props.classes.terms}>
                  I agree to the <span onClick={() => self.showTerms() } className={self.props.classes.termsLink}><u>Terms and Conditions</u></span> of this website
                </Box>
              </Box>
              <Box display="flex" mt={2} justifyContent="center" >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lc--pIaAAAAAPrBvIOGgJuYGMCctmph7EnTk8nz"
                  onChange={onChange}
                />
              </Box>
              <Box display="flex" mt={2} justifyContent="center" >
                <IconButton className={self.props.classes.registerNowButton}
                  onClick={() => self.registerNow()}
                >
                  Register Now
                </IconButton>
              </Box>
            </form>
            </>
          }
          { showForm && approvedToEnter && !loading && !invalidCode && <Box mt={2} justifyContent="center" className={self.props.classes.formContainer}>
          <form name="registrationform" className="registrationform" onSubmit={registrationSubmit.bind(this)}>
            <div className={self.props.classes.overallFormErrorMessage}>{errors["form"]}</div>
            <Box display="flex" justifyContent="center" className={self.props.classes.formInput}>
              <FormControl fullWidth error={errors["firstName"]} className={self.props.classes.formInput}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="firstName"
                  label="First Name *"
                  name="firstName"
                  autoComplete="first name"
                  onChange={handleRegistrationChange}
                  value={self.state.user["firstName"]}
                />
                <FormHelperText>{errors["firstName"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="center" className={self.props.classes.formInput}>
              <FormControl fullWidth error={errors["lastName"]} className={self.props.classes.formInput}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="lastName"
                  label="Last Name *"
                  name="lastName"
                  autoComplete="last name"
                  onChange={handleRegistrationChange}
                  value={user.lastName}
                />
                <FormHelperText>{errors["lastName"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="center" className={self.props.classes.formInput}>
              <FormControl fullWidth error={errors["email"]} className={self.props.classes.formInput}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address *"
                  name="email"
                  autoComplete="email"
                  onChange={handleRegistrationChange}
                  value={user.email}
                />
              <FormHelperText>{errors["email"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="center" className={self.props.classes.formInput}>
              <FormControl fullWidth error={errors["phone"]} className={self.props.classes.formInput}>
                <InputMask
                  mask="(999) 999 9999"

                  value={user.phone}
                  onChange={handleRegistrationChange}
                >
                  {() => <TextField variant="outlined" margin="normal"
                  fullWidth
                  id="phone"
                  label="Phone Number *"
                  name="phone"
                  autoComplete="phone" />}
                </InputMask>
              <FormHelperText>{errors["phone"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box display="flex-row" justifyContent="flex-start" className={self.props.classes.dateOfBirthInputsLabel}>
              Date of Birth *
            </Box>
            <FormControl error={errors["dob"]} className={self.props.classes.dateOfBirthInputs}>
              <Box display="flex" justifyContent="flex-start" className={self.props.classes.dateOfBirthInputs}>
                <Box display="flex-row" justifyContent="flex-start" className={self.props.classes.dateOfBirthDayInput}>
                  <FormControl className={self.props.classes.formInput}>
                    <InputLabel shrink id="day-placeholder-label">
                      Day
                    </InputLabel>
                    <TextField type="number" className={self.props.classes.dateOfBirthDayInputField} inputProps={{ maxLength: 2, min: 0, max: 31 }} value={user.dobDay} onChange={handleRegistrationChange} id="dobDay" name="dobDay" label=" " />
                  </FormControl>
                </Box>
                <Box display="flex-row" justifyContent="flex-start" className={self.props.classes.dateOfBirthMonthInput}>
                  <FormControl className={self.props.classes.formInput}>
                    <label htmlFor="dobMonth" className={self.props.classes.dateOfBirthSelectLabel}>
                      Month
                    </label>
                    <Select placeholder="Select Month" options={self.state.monthOptions} id="dobMonth" label="Month" name="dobMonth" value={user.dobMonth} onChange={handleMonthChange} className={self.props.classes.dateOfBirthSelectInput}/>
                  </FormControl>
                </Box>
                <Box display="flex-row" justifyContent="flex-start" className={self.props.classes.dateOfBirthYearInput}>
                  <FormControl className={self.props.classes.dateOfBirthYearInput}>
                    <InputLabel shrink id="year-placeholder-label">
                      Year
                    </InputLabel>
                    <TextField type="number" className={self.props.classes.dateOfBirthYearInputField} inputProps={{ maxLength: 4, min: 1900, max: 2030 }} value={user.dobYear} onChange={handleRegistrationChange} id="dobYear" name="dobYear" label=" "/>
                  </FormControl>
                </Box>
              </Box>
             <FormHelperText style={{ marginTop: '2%', }}>{errors["dob"]}</FormHelperText>
             </FormControl>
            <IconButton className={self.props.classes.registerNowButtonForm}
              type="submit"
            >
              Register Now
            </IconButton>
          </form>
          </Box>}
          { showForm && notApprovedToEnter && !loading && !invalidCode && <Box mt={2} justifyContent="center" className={self.props.classes.formContainer}>
            Your survey results indicate that you are <span className={self.props.classes.notClearToEnter}>NOT CLEAR</span> to enter<br/><br/>
            Please reach out to "tenant" at "tenant phone" or "tenant email" to discuss
          </Box>}
          { registrationComplete && <Box justifyContent="center" className={self.props.classes.formContainerSuccess}>
            <Box><IconCheck className={self.props.classes.registrationCompleteIcon}/></Box>
            Your registration is complete!<br/>You may now close this window.  Thank you!
          </Box>}
        </>
      )
  }
}

export default function SignInSide() {
  const classes = useStyles();
  return (
    <>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Box display="flex" justifyContent="center" style={{ width: '98%', marginLeft: '1%', marginRight: '1%', }}>
        <div className={classes.paper}>
          <img style={{ width: '35%' }} src={LinkLogo} alt='Link Logo' /><br/>
          <FormContainer classes={classes} />
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Box>
    </Grid>
    </>
  );
}
