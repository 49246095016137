import React from "react";
import Register from "./screens/Register.js";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './App.css';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={["/register", "/"]}>
            <Register />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}


export default App;
